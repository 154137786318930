/*!
 * Less Theme
 * Copyright 2015 panforest.net
 * Opencart Commercial licence
 */
/* ======== Green Freedom Defaulet  theme ======= */
/** twitter bootrap nav bacgrund turn of*/
.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: transparent;
}
body {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  font-weight: 400;
  color: #11151d;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  background: #ffffff;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #11151d;
}
/* default font size */
.fa {
  font-size: 12px;
}
/* Override the bootstrap defaults */
h1 {
  font-size: 38px;
}
h2 {
  font-size: 31px;
}
h3 {
  font-size: 25px;
}
h4 {
  font-size: 18px;
}
h5 {
  font-size: 14px;
}
h6 {
  font-size: 12px;
}
h1,
h4 {
  font-family: 'Playfair Display', serif;
  font-style: italic;
  font-weight: normal;
}
a {
  color: #4a89dc;
}
a:hover {
  text-decoration: none;
}
legend {
  font-size: 21px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.btn-primary {
  border-radius: 0px;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #3a3a3a;
  border-color: #2d2d2d;
}
.btn-primary:focus,
.btn-primary.focus {
  color: #ffffff;
  background-color: #212121;
  border-color: #000000;
}
.btn-primary:hover {
  color: #ffffff;
  background-color: #212121;
  border-color: #0f0f0f;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: #ffffff;
  background-color: #212121;
  border-color: #0f0f0f;
}
.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary.focus {
  color: #ffffff;
  background-color: #0f0f0f;
  border-color: #000000;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus {
  background-color: #3a3a3a;
  border-color: #2d2d2d;
}
.btn-primary .badge {
  color: #3a3a3a;
  background-color: #ffffff;
}
.btn-primary:hover {
  background-color: #ff0037;
  border: 1px solid #ff0037;
}
.btn-group button {
  background: #3a3a3a;
}
.btn-group button .fa {
  color: #ffffff;
}
.btn-group button:hover {
  background: #ff0037;
}
.btn-group button:hover .fa {
  color: #ffffff;
}
.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #ffffff;
  border: 1px solid #ededed;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.well blockquote {
  border-color: #ddd;
  border-color: rgba(0, 0, 0, 0.15);
}
.well-lg {
  padding: 24px;
  border-radius: 6px;
}
.well-sm {
  padding: 9px;
  border-radius: 3px;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropup,
.dropdown {
  position: relative;
}
.dropdown-toggle:focus {
  outline: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #3a3a3a;
  border: 1px solid #585858;
  border-radius: 0px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}
.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #000000;
  white-space: nowrap;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  text-decoration: none;
  color: #262626;
  background-color: #ffffff;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  background-color: #ff0037;
}
.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  color: #777777;
}
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  cursor: not-allowed;
}
.open > .dropdown-menu {
  display: block;
}
.open > a {
  outline: 0;
}
.dropdown-menu-right {
  left: auto;
  right: 0;
}
.dropdown-menu-left {
  left: 0;
  right: auto;
}
.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #777777;
  white-space: nowrap;
}
.dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 990;
}
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}
.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}
@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
    left: auto;
    right: 0;
  }
  .navbar-right .dropdown-menu-left {
    left: 0;
    right: auto;
  }
}
.nav-tabs {
  margin-bottom: 20px;
}
.required .control-label:before {
  content: '* ';
  color: #c9302c;
  font-weight: bold;
}
/** header */
.header {
  background-color: #ffffff;
  border-bottom: 3px solid black;
}
/** top */
.top {
  background-color: #3a3a3a;
  padding-bottom: 0px;
  padding-top: 0px;
}
.top .currency .btn {
  padding-top: 5px;
  font-size: 12px;
  line-height: 1;
}
.top .currency .fa.fa-caret-down {
  font-size: 12px;
  margin-left: 2px;
  vertical-align: middle;
  color: #b3b0b0;
}
.top .currency .btn-link {
  color: #b3b0b0;
  margin-top: 0;
}
.top .currency .currency-select {
  text-align: left;
}
.top .currency .currency-select:hover {
  text-shadow: none;
  color: #ffffff;
}
.top .btn-link {
  color: #b3b0b0;
  text-decoration: none;
  font-size: 12px;
}
.top .btn-link:hover {
  color: #ffffff;
}
.top .btn-link strong {
  font-size: 12px;
}
.top .btn-link .fa.fa-caret-down {
  font-size: 12px;
  margin-left: 2px;
  vertical-align: middle;
  color: #b3b0b0;
}
.top li {
  color: #b3b0b0;
  text-decoration: none;
  font-size: 12px;
}
.top li:hover {
  color: #ffffff;
}
.top li strong {
  font-size: 12px;
}
.top li .fa.fa-caret-down {
  font-size: 12px;
  margin-left: 2px;
  vertical-align: middle;
  color: #b3b0b0;
}
.top li a {
  color: #b3b0b0;
  text-decoration: none;
  font-size: 12px;
}
.top li a:hover {
  color: #ffffff;
}
.top li a strong {
  font-size: 12px;
}
.top li a .fa.fa-caret-down {
  font-size: 12px;
  margin-left: 2px;
  vertical-align: middle;
  color: #b3b0b0;
}
.top-links {
  padding-top: 5px;
}
.top-links .list-inline {
  margin-bottom: 0px;
}
.top-links li {
  color: #b3b0b0;
  text-decoration: none;
  font-size: 12px;
}
.top-links li:hover {
  color: #ffffff;
}
.top-links li strong {
  font-size: 12px;
}
.top-links li .fa.fa-caret-down {
  font-size: 12px;
  margin-left: 2px;
  vertical-align: middle;
  color: #b3b0b0;
}
.top-links li a {
  color: #b3b0b0;
  text-decoration: none;
  font-size: 12px;
}
.top-links li a:hover {
  color: #ffffff;
}
.top-links li a strong {
  font-size: 12px;
}
.top-links li a .fa.fa-caret-down {
  font-size: 12px;
  margin-left: 2px;
  vertical-align: middle;
  color: #b3b0b0;
}
.top-links .dropdown-menu a {
  text-shadow: none;
  color: #b3b0b0;
}
.top-links .dropdown-menu a:hover {
  background-color: transparent;
  color: #ffffff;
}
/* logo */
.logo {
  margin-top: 10px;
  margin-bottom: 10px;
  z-index: 99;
  position: relative;
  height: 80px;
}
.logo img {
  margin-left: auto;
  margin-right: auto;
  height: 80px;
}
/* search */
.search input {
  padding: 0 12px;
  height: 25px;
  background-color: #3a3a3a;
}
.search .btn {
  padding-left: 36px;
  padding-right: 36px;
  background-color: #3a3a3a;
  color: #b3b0b0;
}
.search .form-control,
.search .btn-default {
  border: none;
  border-bottom: 1px solid #6d6d6d;
  color: #ffffff;
  border-radius: 0px;
}
.search .btn-default {
  color: #b3b0b0;
  border-radius: 0px;
  height: 25px;
}
.search .btn-default :hover {
  color: #ffffff;
}
/* cart */
.cart {
  margin-right: 20px;
}
.cart .btn {
  color: #ffffff;
  background-color: transparent;
  border-radius: 0px;
}
.cart .btn :hover {
  color: #ffffff;
}
.cart.open .btn {
  background-color: #ff0037;
  border: 1px solid #ff0037;
  box-shadow: none;
  text-shadow: none;
  color: #ffffff;
}
.cart.open .dropdown-menu {
  background: #ffffff;
  border: 1px solid #ffffff;
  z-index: 1000;
}
.cart.open .dropdown-menu::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../../theme/less-theme/image/placeholder-shadow.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  z-index: -1;
}
@media (max-width: 478px) {
  .cart.open .dropdown-menu {
    width: 100%;
  }
}
.cart.open .dropdown-menu a {
  color: #b30027;
}
.cart.open .dropdown-menu table {
  margin-bottom: 10px;
  color: #000000;
}
.cart.open .dropdown-menu li div {
  min-width: 427px;
  padding: 0 10px;
}
@media (max-width: 478px) {
  .cart.open .dropdown-menu li div {
    min-width: 100%;
  }
}
.cart.open .dropdown-menu li p {
  margin: 20px 0;
  color: black;
}
.cart.open .img-thumbnail {
  background: transparent;
  border-radius: 0px;
  padding: 0px;
}
/* menu */
.menu {
  margin-bottom: 0px;
  z-index: 99;
  min-height: 40px;
}
.menu .nav > li > a {
  color: #000000;
  background-color: transparent;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: normal;
}
@media (max-width: 768px) {
  .menu .nav > li > a {
    font-size: 14px;
    padding: 10px 8px;
  }
}
.menu .nav > li > a:hover {
  color: #ff0037;
  background: rgba(0, 0, 0, 0);
  text-shadow: none;
}
.menu .navbar-nav {
  margin: 0;
  float: none;
  text-align: center;
}
.menu .navbar-nav > li {
  float: none;
  display: inline-block;
}
.menu .navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
}
@media (max-width: 767px) {
  .menu .navbar-nav > li {
    display: block;
  }
}
.menu .dropdown-menu {
  padding-bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #ffffff;
}
@media (max-width: 767px) {
  .menu .dropdown-menu {
    margin-left: 0 !important;
    padding-bottom: 10px;
  }
}
.menu .dropdown-inner {
  display: table;
}
.menu .dropdown-inner ul {
  display: table-cell;
}
.menu .dropdown-inner a {
  min-width: 159px;
  display: block;
  padding: 5px 10px;
  clear: both;
  line-height: 20px;
  color: #000000;
  font-size: 14px;
}
@media (max-width: 767px) {
  .menu .dropdown-inner a {
    width: 100%;
    color: #000;
  }
}
.menu .dropdown-inner li a:hover {
  background-color: #f1f1f1;
}
@media (max-width: 767px) {
  .menu .dropdown-inner {
    display: block;
  }
  .menu .dropdown-inner > .list-unstyled {
    display: block;
  }
}
.menu .see-all {
  display: block;
  padding: 5px 10px;
  border-radius: 0 0 4px 4px;
  font-size: 14px;
  color: #b30027;
  background-color: #ffffff;
}
.menu .see-all:hover {
  text-decoration: none;
  color: #ffffff;
  background-color: #ff0037;
}
@media (max-width: 767px) {
  .menu .see-all {
    margin-top: 0;
    border: none;
    border-radius: 0;
    color: #b30027;
  }
}
.menu .category {
  float: left;
  padding-left: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;
  color: #049372;
  text-shadow: 0 1px 0 #ffffff;
}
.menu .btn-navbar {
  font-size: 20px;
  color: #ffffff;
  padding: 1px 16px;
  float: right;
  background-color: #11151d;
  /*.background-image-linear-gradient(@brand-primary);*/
  /*border-color: @brand-primary darken(@brand-primary,10%) darken(@brand-primary,20%);*/
}
.menu .btn-navbar:hover {
  color: #ffffff;
  background-color: #ff0037;
  background-image: linear-gradient(to bottom, #ff335f, #ff0037);
  background-repeat: repeat-x;
}
@media (min-width: 768px) {
  .menu .dropdown:hover .dropdown-menu {
    display: block;
  }
}
@media (max-width: 767px) {
  .menu {
    border-radius: 4px;
  }
}
.menu .navbar-header > span {
  color: #000000;
  float: left;
  margin-top: 15px;
  font-weight: bold;
  text-transform: uppercase;
}
/* module*/
#headermodule {
  border-bottom: 2px solid #11151d;
  margin-bottom: 50px;
  margin-top: 50px;
}
#headermodule h3 {
  margin-bottom: 0px;
  text-align: center;
  line-height: 5px;
  font-size: 25px;
  font-weight: 400;
  text-transform: uppercase;
  color: #11151d;
}
#headermodule h3 span {
  background-color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
}
/* content */
.content {
  min-height: 705.88235294px;
}
/* footer */
footer {
  margin-top: 40px;
  padding-top: 30px;
  background-color: #11151d;
}
footer hr {
  border-top: none;
  border-bottom: 1px solid #dddddd;
}
footer a {
  color: #cccccc;
  font-size: 13px;
}
footer a:hover {
  color: #ffffff;
}
footer h5 {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  font-size: 14px;
  color: #ffffff;
  text-transform: uppercase;
}
footer p {
  color: #888888;
}
/* alert */
.alert {
  padding: 15px;
}
/* breadcrumb */
.breadcrumb {
  padding: 8px 0;
  border: none;
  background-color: transparent;
  margin-top: 10px;
}
.breadcrumb a {
  color: #4a89dc;
}
.breadcrumb i {
  font-size: 18px;
}
.breadcrumb > li {
  text-shadow: 0 1px 0 #ffffff;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
}
.breadcrumb > li + li:before {
  content: '';
  padding: 0;
}
.breadcrumb > li:after {
  content: '';
  display: block;
  position: absolute;
  top: 6px;
  right: -5px;
  width: 10px;
  height: 10px;
  border-right: 1px solid #4a89dc;
  border-bottom: 1px solid #4a89dc;
  transform: rotate(-45deg);
}
.pagination {
  margin: 0;
}
/* list group */
.list-group a {
  border: 1px solid #777;
  color: #11151d;
  background: #ffffff;
  padding: 6px 12px;
}
.list-group a.active,
.list-group a.active:hover,
.list-group a :hover {
  color: #ffffff;
  background: #ff0037;
  border: 1px solid #777777;
}
.list-group-item:first-child {
  border-radius: 0px;
}
.list-group-item:last-child {
  border-radius: 0px;
}
a.list-group-item:hover {
  color: #000000;
  text-decoration: none;
  background-color: #f1f1f1;
}
.badge {
  font-weight: normal;
}
/* product list */
@media only screen and (min-width: 321px) and (max-width: 767px) and (orientation: landscape) {
  .product-layout.col-xs-12 {
    width: 50%;
  }
}
.product-thumb {
  border: 1px solid #dddddd;
  border-radius: 0;
  margin-bottom: 20px;
  background: transparent;
  overflow: hidden;
  background-color: #ffffff;
  position: relative;
}
.product-thumb .image {
  background: transparent;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.product-thumb .image a {
  display: block;
}
.product-thumb .image img {
  margin-left: auto;
  margin-right: auto;
  -webkit-transition: all 1s ease;
  /* Safari and Chrome */
  -moz-transition: all 1s ease;
  /* Firefox */
  -ms-transition: all 1s ease;
  /* IE 9 */
  -o-transition: all 1s ease;
  /* Opera */
  transition: all 1s ease;
}
.product-thumb .image:hover img {
  -webkit-transform: scale(1.25);
  /* Safari and Chrome */
  -moz-transform: scale(1.25);
  /* Firefox */
  -ms-transform: scale(1.25);
  /* IE 9 */
  -o-transform: scale(1.25);
  /* Opera */
  transform: scale(1.25);
}
.product-thumb h4 {
  font-weight: normal;
  padding: 10px 10px 5px 10px;
  margin: 0;
  font-family: 'Playfair Display', serif;
  font-style: italic;
  height: 56px;
}
.product-thumb h4 a {
  color: #11151d;
  font-size: 16px;
}
.product-thumb h4 a:hover {
  color: #4a89dc;
}
.product-thumb h3 {
  margin: 0;
}
.product-thumb .caption {
  padding: 0 10px;
  text-align: center;
  height: 100%;
}
.product-thumb .caption p {
  display: none;
}
.product-thumb .caption p.price {
  color: radial-gradient(1% 1%, circle cover, #ffffff, #4daf7c);
  text-shadow: none;
  display: block;
  font-size: 15px;
}
.product-thumb .caption .rating {
  display: none;
}
.product-thumb .price {
  font-size: 12px;
  font-weight: normal;
}
.product-thumb .price-new {
  font-size: 15px;
  display: inline-block;
}
.product-thumb .price-old {
  color: #ffffff;
  padding-left: 16px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0, 0);
  text-decoration: line-through;
  background-color: #ff0037;
  padding: 5px 10px;
}
.product-thumb .price-tax {
  color: darknes(#11151d, 25%);
  display: block;
  font-size: 12px;
  font-weight: 500;
  padding: 0;
  display: none;
}
.product-thumb .button-group {
  position: relative;
  top: -54px;
  margin-bottom: -44px;
  background: transparent;
  overflow: auto;
  border: none;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
}
.product-thumb .button-group button {
  border: none;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.7);
  color: #777777;
  font-weight: bold;
  text-transform: uppercase;
  outline: 0;
  padding: 15px;
  border-radius: 25px;
  width: 50px;
  height: 50px;
  background-color: rgba(10, 0, 0, 0.56);
  line-height: 20px;
}
.product-thumb .button-group button + button {
  margin-left: 10px;
}
.product-thumb .button-group button:hover {
  text-decoration: none;
  cursor: pointer;
  color: #ff0037;
  background: #ff0037;
}
.product-thumb .button-group .fa {
  font-size: 20px;
  color: #ffffff;
}
.product-thumb .button-group span {
  display: none;
}
.product-thumb:hover {
  border: 1px solid #777777;
}
.product-grid .product-thumb .image {
  float: none;
}
.product-grid .button-group {
  position: relative;
  top: 0px;
  margin-bottom: 0px;
}
@media (max-width: 1200px) {
  .product-grid .product-thumb .caption {
    padding: 0 10px;
  }
}
@media (max-width: 767px) {
  .product-grid .product-thumb .caption {
    min-height: 0;
  }
}
.product-list .button-group {
  position: relative;
  top: 0px;
  margin-bottom: 0px;
}
@media (min-width: 767px) {
  .product-list .product-thumb {
    text-align: center;
    position: relative;
  }
  .product-list .product-thumb .image {
    background-color: white;
    height: inherit;
    float: left;
  }
  .product-list .product-thumb .caption {
    overflow: hidden;
    text-align: center;
  }
  .product-list .product-thumb .caption p {
    display: block;
  }
  .product-list .product-thumb .caption p.price,
  .product-list .product-thumb .caption p .price-new,
  .product-list .product-thumb .caption p .price-old,
  .product-list .product-thumb .caption p .price-tax {
    text-align: center;
    display: inline-block;
    display: block;
    background: transparent;
    padding: 6px 0;
    font-weight: 600;
    position: inherit;
  }
  .product-list .product-thumb .button-group {
    width: inherit;
    border: none;
  }
  .product-list .product-thumb .rating {
    position: relative;
    background: transparent;
    display: block;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .product-list .product-thumb .caption {
    min-height: 0;
    margin-left: 0;
    padding: 0 10px;
  }
}
@media (max-width: 768px) {
  .product-list .product-thumb .button-group {
    border-left: none;
  }
}
.product-list .product-thumb .rating {
  color: #ffce54;
}
.product-list .price-old {
  color: #656d78;
  padding-left: 16px;
}
.thumbnails {
  overflow: auto;
  clear: both;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: -20px;
}
.thumbnails > li {
  margin-left: 20px;
  float: left;
}
.thumbnails > img {
  width: 100%;
}
.thumbnails .image-additional {
  float: left;
  margin-left: 20px;
}
.thumbnail {
  background: #ffffff;
  border-radius: 0px;
}
.image-additional {
  max-width: 78px;
}
.image-additional a {
  margin-bottom: 20px;
  padding: 5px;
  display: block;
  border: 1px solid #dddddd;
}
.form-control {
  border-radius: 0px;
}
.rating p .fa {
  color: #ffce54;
}
#column-left .product-thumb,
#column-right .product-thumb {
  border: none;
  border-bottom: 1px solid #dddddd;
}
#column-left .product-thumb .image,
#column-right .product-thumb .image {
  width: 35%;
  float: left;
}
#column-left .product-thumb .button-group,
#column-right .product-thumb .button-group {
  display: none;
}
#column-left .product-thumb:hover,
#column-right .product-thumb:hover {
  background: #f7f7f7;
}
/** Owl carousel */
.owl-carousel {
  background-color: #ffffff;
}
.owl-wrapper-outer {
  border: none;
  box-shadow: none;
}
.owl-controls .owl-page {
  display: none;
}
.owl-carousel .owl-buttons div {
  color: #656d78;
  text-shadow: none;
}
/** Tooltip*/
.tooltip {
  background: none;
}
.full-width-slide {
  /*position: relative;
  top: -148px;
  margin-bottom: -130px !important;*/
}
@media (max-width: 767px) {
  .full-width-slide {
    position: relative;
    top: 0px;
    margin-bottom: 20px !important;
  }
}
.advanced-carousel .owl-item {
  overflow: hidden;
  position: relative;
}
.advanced-carousel .owl-item .item {
  overflow: hidden;
  margin: 0 7px;
  display: inline-block;
  height: 100%;
}
.advanced-carousel .owl-item .item img {
  margin-left: auto;
  margin-right: auto;
  -webkit-transition: all 1s ease;
  /* Safari and Chrome */
  -moz-transition: all 1s ease;
  /* Firefox */
  -ms-transition: all 1s ease;
  /* IE 9 */
  -o-transition: all 1s ease;
  /* Opera */
  transition: all 1s ease;
}
.advanced-carousel .owl-item .item img:hover {
  -webkit-transform: scale(1.25);
  /* Safari and Chrome */
  -moz-transform: scale(1.25);
  /* Firefox */
  -ms-transform: scale(1.25);
  /* IE 9 */
  -o-transform: scale(1.25);
  /* Opera */
  transform: scale(1.25);
}
/* overwrite column width to show 1, 2, or 3 columns*/
#column-left .col-lg-3,
#column-right .col-lg-3 {
  width: 100%;
}
#column-left .rating,
#column-right .rating {
  display: none;
}
/** Layout column */
.content.col-sm-9 .col-lg-3 {
  width: 33.33%;
}
.content.col-sm-6 .col-lg-3 {
  width: 50%;
}
@media (max-width: 767px) {
  .content.col-sm-6 .col-lg-3 {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .content.col-sm-9 .col-lg-3 {
    width: 100%;
  }
}
/*# sourceMappingURL=stylesheet.css.map */